#map-container {
  flex: 1 0 100px;
  width: 100%;
  min-height: 400px;
  margin: 0;
}

/**
 * A circular icon which contains an SVG element
 *
 * Inspired by: https://gis.stackexchange.com/a/360454
 * Uses Leaflet's default blue color as the background.
 */
.map-icon {
  box-sizing: border-box;
  border-radius: 50%;
  color: white;
  border: 2px solid white;
  background: rgba(51, 136, 255, 1);
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.2);
  opacity: 0.9;
}

.map-icon.selected {
  color: rgba(51, 136, 255, 1);
  border-color: rgba(51, 136, 255, 1);
  background: white;
}

.map-icon img {
  position: relative;
  top: 2.5px;
  left: 2.5px;
}
